jQuery(function($) {
  
  $('hr.read-more').each(function() {
    var $more = $(this);
    
    var $content = $more.nextAll().addBack();
    $content.wrapAll('<div />');
    var $container = $content.parent();
    $container.attr('data-height', $container.css('overflow', 'auto').height());
    $container.css('overflow', '').addClass('revealable');
    
    $trigger = $('<a class="mobile-only no-print icon-close-circle reveal-button">Read More</a>');
    
    $container.after($trigger);
    
  });
  
  $(document).on('click', '.reveal-button', function() {
    var $trigger = $(this);
    var $container = $trigger.prev();
    var open = !$trigger.hasClass('open');
    $container.toggleClass('open', open);
    $trigger.toggleClass('open', open);
    $trigger.text(open? 'Less' : 'Read More');
    
    var maxHeight = (open? $container.attr('data-height') + 'px' : '');
    $container.css('max-height', maxHeight);
  });
});