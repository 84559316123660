jQuery(function($) {
  'use strict';

  window.validateForm = function($form) {

    $form.validate({
      ignore: ['.recaptcha'],
      errorElement: 'em',
      errorPlacement: function(error, element) {
        element.parent().find('> span').after(error);
      },
      rules: {
        "recaptcha": {
          required: function() {
            var $this = $form.find('.g-recaptcha');
            if (window.grecaptcha && grecaptcha.getResponse($this.attr('id')) == '') {
              // The form on Contact page does not have ID attribute, but it works because
              // recaptcha will default to the first widget on page. The form loaded in
              // the lightbox has an ID set by JS when the lightbox loads.
              return true;
            } else {
              return false;
            }
          }
        }
      },
      submitHandler: function(form) {
        var $form = $(form), $submitter = $form.find('[type="submit"]');
        var $grecaptcha = $form.find('.g-recaptcha[data-size="invisible"]');
        if (window.grecaptcha && $grecaptcha.length) {
          window.validatedForm = form;
          // For invisible ones, we need to manually render and trigger it after form validation.
          var widgetId = grecaptcha.render($grecaptcha[0], {
            sitekey: $grecaptcha.attr('data-sitekey'),
            callback: $grecaptcha.attr('data-callback'),
            size: $grecaptcha.attr('data-size'),
            badge: $grecaptcha.attr('data-badge')
          });

          // We only need one badge.
          $('.grecaptcha-badge').hide();

          grecaptcha.reset(widgetId);
          grecaptcha.execute(widgetId);
          return;
        }

        $submitter.prop("disabled",true);

        form.submit();
      }
    });
  }

  $('.page-content form:not(#editForm)').each(function() {
    validateForm($(this));
  });
});