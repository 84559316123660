import dialogPolyfill from "../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const inSAM = $$("body.samadmin").length > 0;

document.querySelectorAll("dialog.hla-dialog").forEach(dialog => {
  dialogPolyfill.registerDialog(dialog);
  // Handles "faux" close button using empty anchor to clear the `target:`
  const closeButton = dialog.querySelector(`a[href="#close"`);
  if (closeButton) {
    closeButton.addEventListener("click", function(event) {
      dialog.close();
    });
  }
});

$$(".job-cta").forEach(cta => {
  const dialog = $("dialog", cta);
  const closeButton = $("button.close", dialog);
  const dialogButton = $("button", cta);

  dialogButton.addEventListener("click", function(event) {
    dialogButton.setAttribute("aria-expanded", "true");
    dialog.showModal();
  });

  closeButton.addEventListener("click", function(event) {
    dialogButton.setAttribute("aria-expanded", "false");
    dialog.close();
  });
});