jQuery(function($) {
  $('.news-listing').each(function() {
    var $listing = $(this), 
        $years = $listing.find('.year'),
        $tabs = $listing.find('.articles');
    
    $years.click(function() {
      var $year = $(this), year = $year.attr('href').substr(1);
      $years.removeClass('active');
      $year.addClass('active');
      
      $tabs.removeClass('active');
      $tabs.filter('[data-year="' + year + '"]').addClass('active')
      .find('.pagination .page').eq(0).click();
    }).eq(0).click();
    
/*
    function paginate($container, page) {
      var perPage = Number($container.attr('data-paging')),
          $articles = $container.find('article');
          
      $articles.hide().slice(page * perPage, (page + 1) * perPage).show();
    }
    
    $listing.on('click', '.pagination .page', function(event) {
      event.preventDefault();
      
      var $page = $(this),
          page = Number($page.attr('href').substr(1)),
          $pages = $page.siblings('.page'),
          $container = $page.closest('.articles');
          
      $pages.removeClass('active');
      $page.addClass('active');
      
      paginate($container, page);
    });
    
    $listing.on('click', '.pagination .previous', function(event) {
      event.preventDefault();
      
      var $pages = $(this).closest('.pagination').find('.page'),
          $active = $pages.filter('.active'),
          index = $pages.index($active);
          
      if (index > 0) {
        $active.prev().click();
      }
    });
    
    $listing.on('click', '.pagination .next', function(event) {
      event.preventDefault();
      
      var $pages = $(this).closest('.pagination').find('.page');
      var $active = $pages.filter('.active'),
          index = $pages.index($active);
      
      if (index < $pages.length - 1) {
        $active.next().click();
      }
    });
    
    $tabs.each(function() {
      paginate($(this), 0);
    });
*/
  });
  
});