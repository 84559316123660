jQuery(function($) {
  'use strict';
  var $document = $(document), $body = $('body'),
      $scrollers = $('html, body'),
      $SAM = $('#SAMAdminTools'),
      $navigation = $('.navigation-container'),
      $mobileMenu = $('.mobile-menu');

  function isMobile() {
    return $mobileMenu.is(':visible');
  }

  // Helpers:
  function numberArray(a,b){
   b=[];while(a--)b[a]=a;return b
  }
  // Fisher-Yates shuffle algorithm
  function shuffle(array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle�
    while (m) {

      // Pick a remaining element�
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

  $('.featured-attorneys').each(function() {
    var $container = $(this), $attorneys = $container.find('.attorney');

    var indices = numberArray($attorneys.length);
    // Shuffle the indices.
    shuffle(indices);

/*
    $attorneys.eq(indices[0]).show().css("display", "inline-block");
    $attorneys.eq(indices[1]).show().css("display", "inline-block");
*/
    $attorneys.filter(function(index) {
      return index !== indices[0] && index !== indices[1];
    }).remove();
  });

  function scrollToAnchor($anchor) {
    if ($anchor.length) {
      $scrollers.animate({
        scrollTop: $anchor.offset().top - $navigation.height() - ($SAM.height() || 0)
      }, 1250,'easeInOutExpo');
    }
  }

  var path = window.location.origin + window.location.pathname;
  $document.on('click', 'a:not([href="'+path+'#"])[href^="'+path+'#"], a:not([href="#"])[href^="#"]', function(e) {
    var $link = $(e.target);
    var href = $link.attr('href') || '';
    var id = href.substring(href.indexOf("#"));
    scrollToAnchor($(id));
  });
  scrollToAnchor($('#' + (window.location.hash || "#").substring(1)));

  var $tabs = $('.tabs .tab'), $tabLabels = $('.tab-labels a, a.tab-label');
  $body.on('click', 'a.tab-label[href^="#"], .tab-labels a[href^="#"]', function(event) {
    var $label = $(this), href = $label.attr('href');
    var $tab = $tabs.filter(href);
    if ($tab.length) {
      event.preventDefault();
      var isActive = $tab.hasClass('active');

      // Clicking on an active tab - do nothing on desktop, but collapse on mobile.
      if (isActive && isMobile()) {
        $tabLabels.removeClass('active');
        $tab.closest('.tab').removeClass('active')
        return true;
      }

      // We use siblings here instead of $tabs because there can be multiple tab containers in page.
      $tab.siblings('.tab').removeClass('active');
      $tab.addClass('active');
      // All tab labels, including tab anchors matching the href get `.active`
      $tabLabels.removeClass('active')
      .filter('[href="' + href + '"]').addClass('active');

      if (isMobile()) {
        var scrollTo = $tab.offset().top - $navigation.height() - ($SAM.height() || 0);
        $scrollers.animate({
          scrollTop: scrollTo
        });
      }
    }
  });

  // Set the first anchor tab to be active if none is set.
  var $anchorTabs = $('.tab-labels a');
  if ($anchorTabs.filter('.active').length === 0 && $anchorTabs.length > 0) {
    $anchorTabs.first()[0].click();
  }

  $('nav.pagination').on('click', 'a', function() {
    var scrollTo = $(this).closest('.articles').siblings('.news-header').offset().top - $navigation.height() - ($SAM.height() || 0) - 30;
    $scrollers.animate({
      scrollTop: scrollTo
    });
  });

  $(".clickable").on('click', function(e) {
    $(this).find("a")[0].click();

    if($(this).hasClass("profile")){
      if(dataLayer) {
        dataLayer.push({
          "event": "click",
          "profile_name": $(this).find("a").html()
        });
      }
    }
  });

  $('.accordion').each(function() {
    var $accordion = $(this);
    $accordion.collapse({
      query: 'div .summary',
      clickQuery: '> a',
      open: function() {
        this.slideDown(150);
      },
      close: function() {
        this.slideUp(150);
      }
    }).on('click', '.close a', function(event) {
      event.preventDefault();
      $accordion.trigger('close');
    });
  });

  $("a.login").click(function(e) {
    var $this = $(this);
    var href = $this.attr("href");
    var options = {
      closeIcon: "",
      afterOpen: function() {
        $('body').css('overflow', 'hidden');
      },
      afterClose: function() {
        $('body').css('overflow', '');
      },
      type: "ajax"
    };
    e.preventDefault();

    $.featherlight(href + " main", options);
  });

  $('[rel="lightbox"]').each(function() {
    var $anchor = $(this),
        href = $anchor.attr('href'),
        selector = $anchor.attr('data-selector');

    if (selector) {
      $anchor.attr('href', href + ' ' + selector);
    }

    $anchor.on('click.lightbox', function(e) {
      // We don't want the click event on this to buble up.
      e.stopPropagation();
    });

    $anchor.featherlight({
      closeIcon: '',
      iframeMaxWidth: '90%',
      afterOpen: function() {
        $('body').css('overflow', 'hidden');
        var $instance = this.$instance, $captcha = $instance.find('.g-recaptcha');

        if ($captcha.length && window.grecaptcha) {

          var widgetId = grecaptcha.render($captcha[0], { sitekey: $captcha.attr('data-sitekey')});
          $captcha.attr('id', widgetId);
        }
        window.validateForm(this.$instance.find('form'));
      },
      afterClose: function() {
        $('body').css('overflow', '');
      }
    });
  });

  // Store the previous Url in cookie.
  var previousUrl = document.referrer;
  if (previousUrl) {
    $('.back.button').attr('href', previousUrl);
  } else {
    $('.back.button').remove();
  }

  $('.BEShare').BEShare({
    'type': 'inline',
    'targets': ['Facebook', 'LinkedIn', 'Twitter', 'Print', 'Email'],
    'via': 'HanszenLaporte'
  }).children('a').after(" "); // We need a nbsp between the anchors for justifing them.


  if (!$SAM.length) {

    $document.on('click', '.social-links a', function() {
      ga('send', 'event', 'Social', 'Visits', $(this).attr('rel'));
    }).on('click', '.chat-button', function() {
      var position = $(this).hasClass('bottom-right')? 'Bottom' : 'Side';
      ga('send', 'event', 'Chat', 'Started', position);
    }).on('click', '.BEShare > a', function() {
      ga('send', 'event', 'Bio', 'Share', $(this).text());
    }).on('click', 'a[href$=".vcf"]', function() {
      ga('send', 'event', 'VCard', 'Download', $('.bio-name').text());
    }).on('click', 'body.practice aside.right-sidebar .button-outline', function() {
      ga('send', 'event', 'Button', 'Click', $(this).text());
    }).on('click', 'a[href^="mailto:"]', function() {
      var address = $(this).attr('href').replace(/^mailto\:/i, '');
      ga('send', 'event', 'Email-'+($body.hasClass('bios')? 'Staff' : 'Attorney'), 'Started', address);
    }).on('click', 'a[href*="google.com/maps/dir/"]', function() {
      ga('send', 'event', 'Link', 'Click', 'Directions');
    }).on('submit', 'form.search-form', function(event) {
      // Prevents the browser from submiting the form
      // and thus unloading the current page.
      event.preventDefault();

      var form = this;
      var $q = $(form).find('input[name="q"]');
      form.submit();
    });
  }
});