jQuery(function($) {
  'use strict';
  var $body = $("body");
  var $secondary = $('.page-header nav.secondary');

  if($body.hasClass("samadmin")) {
    var offset = '97px';
  } else {
    var offset = '0px';
  }

  new Waypoint.Sticky({
    element: $('.navigation-container')[0],
    offset: offset
  });

/*
  new Waypoint.Sticky({
    element: $('header.page-header')[0],
    offset: offset
  });
*/

  $('.search-toggle').click(function(event) {
    event.preventDefault();

    var $toggle = $(this);

    $toggle.toggleClass('active');

    $secondary.toggleClass('searching')

    if ($toggle.hasClass('active')) {
      $secondary.find('input[name="q"]').focus()
      .on('focusout.search', function() {
        $toggle.removeClass('active');
        $(this).off('focusout.search');
        $secondary.removeClass('searching');
      });
    }
  });

  $("#menu-toggle").click(function(){
    if(this.checked) {
      $(".page-container").addClass("menu-open");
      $body.css('overflow-y', 'hidden');
    } else {
      $(".page-container").removeClass("menu-open");
      $body.css('overflow-y', '');
    }
  });

  // Click on mobile nav
  $('.page-container').on('click', '#menu-toggle:checked ~ .page-header nav.primary li.isParent > a', function(event) {
    event.preventDefault();
    var $primaryNav = $(this), $secondary = $primaryNav.next('.secondary-nav');
    var visible = $secondary.height() > 0 && $secondary.is(':visible');

    if (visible) {
      $secondary.slideUp(100);
      $primaryNav.closest('li').removeClass('active');
    } else {
      $secondary.hide().css('height', 'auto').slideDown(100);
      $primaryNav.closest('li').addClass('active');
    }
  });
});
